<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <b-row class="mt-3">
                <b-col md="4">
                    <b-form-group
                        :label="i18nDate"
                        class="required label-pdv"
                        label-for="filter-date"
                    >
                        <date-range-picker
                            :value.sync="filters.date"
                            :max-date="new Date()"
                            :disabled="loading"
                            :disable-clean-button="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                        :label="i18nBusinessUnit"
                        class="label-pdv"
                        label-for="filter-business-unit"
                    >
                        <multiSelectWithService
                            :id="'filter-business-unit'"
                            ref="businessUnitMultiselect"
                            v-model="filters.business_units"
                            :service="'business-units'"
                            :searchable="true"
                            :multiple="true"
                            :parameters="filterParameters"
                            :disabled="loading"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                        :label="i18nQuestionnaire"
                        label-for="filter-questionnaire"
                        class="label-pdv">
                        <multiSelectWithService
                            :id="'filter-questionnaire'"
                            ref="questionnaireMultiselect"
                            v-model="filters.questionnaires"
                            :service="'questionnaires'"
                            :searchable="true"
                            :multiple="true"
                            :parameters="questionnaireFilterParameters"
                            :disabled="loading"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center mb-3">
                <b-col md="4">
                    <more-filters-label-button class="col-sm-12 float-right" :disabled="loading" @onClick="showFilterModal" />
                </b-col>
                <b-col md="4">
                    <filter-button class="col-sm-12 col-md-12" :disabled="loading" @onClick="refresh(true)" />
                </b-col>
                <b-col md="4">
                    <button
                        type="button"
                        class="btn btn-pdv btn-pdv-blue col-sm-12 "
                        href="javascript: void(0);"
                        :disabled="loading"
                        @click="showExportPresentationJobModal">
                        <i class="fe-download mr-1"></i>
                        Gerar Relatório de Foto
                    </button>
                </b-col>
            </b-row>
            <div
                v-infinite-scroll="refresh"
                infinite-scroll-disabled="disableInfiniteScroll"
                infinite-scroll-distance="limit"
                infinite-scroll-throttle-delay="300"
                >
                <div v-if="data.length > 0">
                    <b-row>
                        <b-col v-for="photo in data" :key="photo.id" md="6" xl="4">
                            <div class="card card-pdv">
                                <div class="card-body">
                                    <div class="ml-3 mr-3">
                                        <b-row class="m-auto imageGalleryPreDiv">
                                            <div class="imageGalleryDiv d-flex justify-content-center">
                                                <a
                                                    v-if="photo && photo.url"
                                                    :href="photo.url"
                                                    loading="lazy"
                                                    target="_blank"
                                                >
                                                    <img
                                                        :src="photo.url"
                                                        fluid
                                                        class="m-auto"
                                                        alt="Cover Image"
                                                    />
                                                </a>
                                                <img
                                                    v-else
                                                    src="@assets/images/no-image.png"
                                                    fluid
                                                    class="m-auto"
                                                    alt="No Image Upload"
                                                />
                                            </div>
                                        </b-row>
                                        <b-row class="mt-2 border-bottom">
                                            <b-col sm="4" class="font-weight-bold">
                                                {{ i18nDate }}
                                            </b-col>
                                            <b-col sm="8">
                                                {{ formatDate(photo.date) }}
                                            </b-col>
                                        </b-row>
                                        <b-row class="border-bottom">
                                            <b-col sm="4" class="font-weight-bold">
                                                {{ i18nBusinessUnit }}
                                            </b-col>
                                            <b-col sm="8">
                                                {{ photo.bu_name }}
                                            </b-col>
                                        </b-row>
                                        <b-row class="border-bottom">
                                            <b-col sm="4" class="font-weight-bold">
                                                {{ i18nRegional }}
                                            </b-col>
                                            <b-col sm="8">
                                                {{ photo.pos_regional_name ? photo.pos_regional_name : i18nUninformed }}
                                            </b-col>
                                        </b-row>
                                        <b-row class="border-bottom">
                                            <b-col sm="4" class="font-weight-bold">
                                                {{ i18nChain }}
                                            </b-col>
                                            <b-col sm="8">
                                                {{ photo.pos_chain_name ? photo.pos_chain_name : i18nUninformed  }}
                                            </b-col>
                                        </b-row>
                                        <b-row class="border-bottom">
                                            <b-col sm="4" class="font-weight-bold">
                                                {{ i18nPos }}
                                            </b-col>
                                            <b-col sm="8">
                                                {{ photo.pos_name ? photo.pos_name : i18nUninformed  }}
                                            </b-col>
                                        </b-row>
                                        <b-row class="border-bottom">
                                            <b-col sm="4" class="font-weight-bold">
                                                {{ i18nUser }}
                                            </b-col>
                                            <b-col sm="8">
                                                {{ photo.user_name }}
                                            </b-col>
                                        </b-row>
                                        <b-row class="border-bottom">
                                            <b-col sm="4" class="font-weight-bold">
                                                {{ i18nQuestionnaire }}
                                            </b-col>
                                            <b-col sm="8">
                                                {{ photo.questionnaire_name }}
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="photo.status" class="d-flex justify-content-center">
                                            <label v-if="photo.status === 'approved'" class="d-flex justify-content-center align-items-center status-approved">
                                                {{ statusText(photo.status) }}
                                                <comment-label-button @onClick="handleCommentModal(photo.id, photo.comment)" />
                                            </label>
                                            <div v-else class="d-flex justify-content-center align-items-center status-approved-comment">
                                                <label
                                                    id="status-denied"
                                                    class="status-denied"
                                                >
                                                    {{ statusText(photo.status) }}
                                                </label>
                                                <a
                                                    href="javascript: void(0);"
                                                    @click="showReasonModal(photo.comment)">
                                                    <i class="fe-eye icon-blue ml-2"></i>
                                                </a>
                                            </div>
                                        </b-row>
                                        <b-row v-else class="d-flex justify-content-center status-approved-comment">
                                            <reject-label-button class="mr-1" @onClick="handleRejectPhoto(photo.id)" />
                                            <approve-label-button class="ml-1" @onClick="handleApprovePhoto(photo.id, photo.comment)" />

                                            <div class="position-relative">
                                                <comment-label-button class="mr-1" @onClick="handleCommentModal(photo.id, photo.comment)" />

                                                <!-- Indicador se houver comentário -->
                                                <span v-if="photo.comment" class="fas fa-comments comment-indicator"></span>
                                            </div>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div v-if="loading && data.length <= 0">
                    <b-row>
                        <b-col v-for="(index) in perPage" :key="index" md="4" lg="3">
                            <div class="card card-pdv">
                                <div class="card-body mt-2">
                                    <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
                                    <b-skeleton class="mt-1"></b-skeleton>
                                    <b-skeleton></b-skeleton>
                                    <b-skeleton></b-skeleton>
                                    <b-skeleton></b-skeleton>
                                    <b-skeleton></b-skeleton>
                                    <b-skeleton></b-skeleton>
                                    <b-skeleton></b-skeleton>
                                    <b-skeleton class="mt-2" height="20px"></b-skeleton>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div v-else-if="loading && data.length > 0" class="text-center">
                    <b-spinner
                        class="ml-auto"
                        variant="primary"
                        style="width: 3rem; height: 3rem;"
                    />
                </div>
                <div v-if="data.length <= 0 && !loading">
                    <no-records-found />
                </div>
                <div v-else-if="currentPage >= lastPage && disableInfiniteScroll">
                    <end-timeline />
                </div>
            </div>
        </div>
        <export-presentation-job-modal ref="exportPresentationJobModal" @refresh="refreshTable" />
        <photo-gallery-filter-modal ref="photoGalleryFilterModal" :handle-submit="refresh" />
        <photo-gallery-confirmation-modal ref="photoGalleryConfirmationModal" @refresh="updatePhoto" />
        <photo-gallery-comment-modal ref="photoGalleryCommentModal" @refresh="updateComment" />
        <photo-gallery-reason-modal ref="photoGalleryReasonModal" />
    </Layout>
</template>

<script>
    import PhotoGalleryService from '@/src/services/dashboards/PhotoGalleryService.js'

    import Layout from '@layouts/main'
    import Breadcrumb from '@/src/components/Breadcrumb.vue'
    import DateRangePicker from '@/src/components/DateRangePicker'
    import multiSelectWithService from '@src/components/multiSelectWithService'
    import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
    import RejectLabelButton from '@/src/components/RejectLabelButton.vue'
    import ApproveLabelButton from '@/src/components/ApproveLabelButton.vue'
    import CommentLabelButton from '@/src/components/CommentLabelButton.vue'
    import FilterButton from '@/src/components/FilterButton'
    import EndTimeline from '@/src/components/EndTimeline'
    import NoRecordsFound from '@/src/components/NoRecordsFound'
    import photoGalleryFilterModal from '@/src/router/views/dashboards/photoGallery/photoGalleryFilterModal.vue'
    import photoGalleryConfirmationModal from '@/src/router/views/dashboards/photoGallery/photoGalleryConfirmationModal.vue'
    import photoGalleryCommentModal from '@/src/router/views/dashboards/photoGallery/photoGalleryCommentModal.vue'
    import photoGalleryReasonModal from '@/src/router/views/dashboards/photoGallery/photoGalleryReasonModal.vue'
    import exportPresentationJobModal from '@/src/router/views/exportPresentationJob/exportPresentationJobModal';
    import filterVuetable from '@src/mixins/filterVuetable'
    import vuetableFormatter from '@src/mixins/vuetableFormatters'
    import moment from 'moment';

    const i18nKey = 'DASHBOARDS'
    const i18nCommon = 'COMMON'

    export default {
        components: {
            Layout,
            Breadcrumb,
            DateRangePicker,
            multiSelectWithService,
            MoreFiltersLabelButton,
            RejectLabelButton,
            ApproveLabelButton,
            CommentLabelButton,
            FilterButton,
            EndTimeline,
            NoRecordsFound,
            photoGalleryFilterModal,
            photoGalleryConfirmationModal,
            photoGalleryCommentModal,
            photoGalleryReasonModal,
            exportPresentationJobModal,
        },
        mixins: [
            filterVuetable,
            vuetableFormatter,
        ],
        data() {
            return {
                i18nKey,
                i18nCommon,
                loading: false,
                enableInfiniteScroll: false,
                lastPage: 999,
                currentPage: 0,
                perPage: 10,
                totalItems: 0,
                additionalParameters: {},
                filterParameters: {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                },
                questionnaireFilterParameters: {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                    only_file: true
                },
                filters: {
                    date: {
                        start: moment().startOf('day').add(-7, 'days').toDate(),
                        end: moment().startOf('day').toDate()
                    },
                    business_units: [],
                    regionals: [],
                    chains: [],
                    flags: [],
                    channels: [],
                    states: [],
                    categories: [],
                    point_of_sales: [],
                    users: [],
                    questionnaires: [],
                },
                data: [],
            }
        },
        computed: {
            items() {
                return [
                    {
                        text: 'Home',
                        href: '/',
                    },
                    {
                        text: this.getI18nModified({
                            prefix: i18nKey,
                            suffix: 'TITLES.dashboards',
                            modifier: 2,
                        }),
                        active: true,
                    },
                    {
                        text: this.i18nPhotoGallery,
                        active: true,
                    },
                ]
            },
            i18nPhotoGallery() {
                return this.getI18n(i18nKey, 'TITLES.photo_gallery')
            },
            i18nDate() {
                return this.getI18n(i18nCommon, 'date')
            },
            i18nBusinessUnit() {
                return this.getI18n(i18nCommon, 'business_unit')
            },
            i18nRegional() {
                return this.getI18n(i18nCommon, 'regional')
            },
            i18nChain() {
                return this.getI18n('POS', 'TITLES.chain')
            },
            i18nPos() {
                return this.getI18n('EXPORT_PRESENTATION_JOBS', 'TITLES.pos_alias')
            },
            i18nUser() {
                return this.getI18n('USERS', 'TITLES.user')
            },
            i18nQuestionnaire() {
                return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
            },
            i18nUninformed() {
                return this.getI18n(i18nCommon, 'uninformed')
            },
            disableInfiniteScroll() {
                return !this.enableInfiniteScroll
            },
        },
        methods: {
            statusText(text) {
                return this.getI18n(i18nKey, `TYPES.${text}`)
            },
            statusComment() {
                return this.getI18n(i18nKey, `TYPES.comment_photo`)
            },
            createAdditionalParameters() {
                const period = this.formatFilterDate(this.filters.date)
                return this.formatParameters({
                    business_unit_id: this.filters.business_units.map((businessUnit) => businessUnit.id),
                    regional_id: this.filters.regionals.map((regional) => regional.id),
                    chain_id: this.filters.chains.map((chain) => chain.id),
                    product_category_id: this.filters.categories.map((category) => category.id),
                    flag_id: this.filters.flags.map((flag) => flag.id),
                    channel_id: this.filters.channels.map((channel) => channel.id),
                    states: this.filters.states.map((state) => state.value),
                    point_of_sale_id: this.filters.point_of_sales.map((pos) => pos.id),
                    user_id: this.filters.users.map((user) => user.id),
                    questionnaire_id: this.filters.questionnaires.map((questionnaire) => questionnaire.id),
                    start: period.from,
                    end: period.to,
                    per_page: this.perPage,
                    page: this.currentPage,
                    orderBy: 'id',
                    sortedBy: 'desc'
                })
            },
            showFilterModal() {
                this.$nextTick(() => this.$refs.photoGalleryFilterModal.showModal(this.filters))
            },
            showReasonModal(comment) {
                this.$nextTick(() => this.$refs.photoGalleryReasonModal.showModal(comment))
            },
            showExportPresentationJobModal() {
                this.$refs.exportPresentationJobModal.showModal()
            },
            handleRejectPhoto(id) {
                this.$nextTick(() => this.$refs.photoGalleryConfirmationModal.showModal(id, false))
            },
            handleApprovePhoto(id, comment) {
                this.$nextTick(() => this.$refs.photoGalleryConfirmationModal.showModal(id, true, comment))
            },
            updatePhoto (id, status, comment) {
                const currentItem = this.data.find((dataItem) => dataItem.id === id)
                if (status !== currentItem.status) {
                    currentItem.status = status
                    currentItem.comment = comment
                }
            },
            updateComment (id, comment) {
                const currentItem = this.data.find((dataItem) => dataItem.id === id)
                currentItem.comment = comment
            },
            handleCommentModal(id, comment) {
                this.$nextTick(() => this.$refs.photoGalleryCommentModal.showModal(id, comment))
            },
            refresh(appliedFilters) {
                if (appliedFilters) {
                    this.enableInfiniteScroll = true
                    this.lastPage = 999
                    this.currentPage = 0
                    this.data = []
                    if (typeof appliedFilters === 'object') {
                        this.filters = appliedFilters
                    }
                }

                this.currentPage += 1

                if (this.currentPage > this.lastPage) {
                    this.enableInfiniteScroll = false
                    return
                }

                this.additionalParameters = this.createAdditionalParameters()
                this.loading = true

                PhotoGalleryService.fetchAll(this.additionalParameters)
                .then((response) => {
                    response.data.data.forEach((item) => {
                      const currentItem = this.data.find((dataItem) => dataItem.id === item.id)
                    if (!currentItem) {
                            this.data.push(item)
                      }
                    })
                    this.lastPage = response.data.meta.last_page
                    this.totalItems = response.data.meta.total
                    this.perPage = response.data.meta.per_page
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
            },
            refreshTable() {
                //
            }
        },
    }
</script>

<style lang="scss">
    .imageGalleryPreDiv {
        width: 100%;
        height: 250px;
    }
    .imageGalleryDiv {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 250px;
        border: 0;
    }
    .imageGalleryDiv > a > img {
        max-width: 100%;
        max-height: 100%;
    }
    .imageGalleryDiv > img {
        max-width: 100%;
        max-height: 100%;
    }
    .status-approved {
        margin-top: 8px;
        color: #64BB77;
    }
    .status-approved-comment {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .status-denied {
        margin-top: 8px;
        color: #AF0404;
    }
    .comment-indicator {
        position: absolute;
        top: 0;
        right: -8px;
        font-size: 14px;
    }
</style>
